<template>
  <div class="look-feed container" v-infinite-scroll="loadFeed">
    <look-feed-header @paramsUpdate="onParamsUpdate"></look-feed-header>
    <div class="looks">
      <look-feed-item v-for="look in looks"
                 :look="look"
                 :key="look.id"></look-feed-item>
    </div>
    <div class="loader-wrapper" v-if="loading">
      <base-loader></base-loader>
    </div>
  </div>
</template>

<script>
  import feedService from '@/services/queries/feedQueries.js'
  import hasBackgroundMixin from '@/services/mixins/hasBackgroundMixin.js'
  import LookFeedHeader from './LookFeedHeader.vue'
  import LookFeedItem from './LookFeedItem.vue'

  export default {
    components: {
      LookFeedHeader,
      LookFeedItem
    },

    data() {
      return {
        loading: false,
        looks: [],

        limit: 15,
        offset: 0,
        total: 0,

        params: {}
      }
    },

    created() {
      this.loadFeed()
    },

    mixins: [hasBackgroundMixin],

    methods: {
      onParamsUpdate(value) {
        let params = _.cloneDeep(value)

        if (params.is_only_agents) {
          this.params.user_type = this.constants$.userTypes.agent
        } else {
          delete this.params.user_type
        }

        delete params.is_only_agents // Don't send garbage to server

        this.params = {...this.params, ...params}
      },

      loadFeed(append = true) {
        if (this.loading) {
          return false
        }

        if (this.offset > this.total) {
          return
        }

        let params = {...this.params}

        params.limit  = this.limit
        params.offset = this.offset

        this.loading = true

        feedService.get(params).then(data => {
          this.looks.push(...data.items)

          this.total = data.total_count
          this.offset = this.offset + this.limit

          this.loading = false
        })
      }
    },

    watch: {
      params() {
        this.offset = 0
        this.looks = []

        this.loadFeed()
      }
    }
  }
</script>

<style lang="scss" scoped>
  .look-feed {
    width: 940px;
    padding-top: 40px;

    .looks {
      display: flex;
      flex-direction: column;
    }

    .loader-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      margin: 40px 0;
    }
  }
</style>