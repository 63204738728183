<template>
  <div class="look-feed-filter-price">
    <div class="label" :class="{ active: isOpen }" ref="priceFilter">
      {{ $t('lookFeed.lookFeedFilterPrice.title') }}
    </div>
    <div class="dropdown" ref="priceFilterDropdown">
      <div class="inputs">
        <base-text-box :value="maskedPriceMin"
                       class="price-input"
                       :placeholder="$t('lookFeed.lookFeedFilterPrice.priceMin')"
                       @input="setPriceMin"></base-text-box>
        <base-text-box :value="maskedPriceMax"
                       class="price-input"
                       :placeholder="$t('lookFeed.lookFeedFilterPrice.priceMax')"
                       @input="setPriceMax"></base-text-box>
      </div>

      <look-feed-filter-action-buttons
              class="actions"
              @clear="clear"
              @apply="apply"></look-feed-filter-action-buttons>
    </div>
  </div>
</template>

<script>
  import LookFeedFilterActionButtons from './LookFeedFilterActionButtons.vue'
  import { maskPrice, unmaskPrice } from '@/services/utils'

  export default {
    components: {
      LookFeedFilterActionButtons
    },

    data() {
      return {
        priceMin: '',
        priceMax: '',
        isOpen: false
      }
    },

    mounted() {
      let vm = this

      tippy(this.$refs.priceFilter, {
        content: this.$refs.priceFilterDropdown,
        interactive: true,
        trigger: 'click',
        theme: 'light dropdown-menu',
        animation: 'shift-away',
        arrow: true,
        placement: 'bottom',
        inertia: true,
        duration: [600, 300],
        onShow() {
          vm.isOpen = true
        },
        onHide() {
          vm.isOpen = false
        }
      })
    },

    methods: {
      close() {
        this.$refs.priceFilter._tippy.hide()
      },

      clear() {
        this.priceMin = ''
        this.priceMax = ''
        this.apply()
      },

      apply() {
        this.close()
        this.$emit('apply', {
          "estimated_price.min": isNaN(parseInt(this.priceMin)) ? null : parseInt(this.priceMin),
          "estimated_price.max": isNaN(parseInt(this.priceMax)) ? null : parseInt(this.priceMax)
        })
      },

      setPriceMin(value) {
        this.priceMin = unmaskPrice(value)
      },

      setPriceMax(value) {
        this.priceMax = unmaskPrice(value)
      }
    },

    computed: {
      maskedPriceMin() {
        return maskPrice(this.priceMin)
      },

      maskedPriceMax() {
        return maskPrice(this.priceMax)
      }
    }
  }
</script>

<style lang="scss" scoped>


  .look-feed-filter-price {
    .label {
      font-size: 14px;
      color: #797979;
      cursor: pointer;

      &:hover, &.active {
        color: $primary-color;
      }
    }
  }

  .dropdown {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 600px;

    .inputs {
      display: flex;

      .price-input {
        margin: 10px;
      }
    }

    .actions {
      justify-content: flex-end;
      margin-left: 100px;
    }
  }
</style>
