<template>
  <div class="look-item">
    <div class="preview">
      <router-link :to="{ name: 'look', params: { userId: look.authorId, lookId: look.id }}">
        <img :src="look.previewUrl">
      </router-link>
    </div>
    <div class="info">
      <div class="user">
        <base-avatar :user="look.author"></base-avatar>
        <div class="name-and-date">
          <router-link :to="{ name: 'persons.profile', params: { id: look.author.user_id }}"
                       class="name">
            {{ look.author.nick_name }}
          </router-link>
          <div class="date">{{ look.createdAt | date }}</div>
        </div>
      </div>
      <div class="title">{{ look.title }}</div>
      <div class="description">{{ look.description }}</div>
      <ul class="meta">
        <li class="characteristic"
            v-if="look.lookContext">
          <div class="name">
            {{ $t('lookFeed.lookItem.lookContext') }}
          </div>
          <div class="value">
            {{ look.lookContext.title }}
          </div>
        </li>
      </ul>
      <div class="actions">
        <div class="action" @mouseover="isLikeIconHovered = true"
             @mouseleave="isLikeIconHovered = false"
             @click="onLike">
          <base-icon-like :active="liked || isLikeIconHovered"/>
          <div class="title">
            {{ likesNumber }}
          </div>
        </div>
        <div class="action"
             ref="cart"
             @click="toCart">
          <base-icon class="icon" icon="cart"></base-icon>
          <div class="title">
            {{ $t('lookFeed.cart.toCart') }}
          </div>
        </div>
        <div class="action" @click="goToStudio(look)">
          <base-icon icon="studio"></base-icon>
          <div class="title">
            {{ $t('lookFeed.toStudio') }}
          </div>
        </div>
        <div class="action" ref="socialSharing">
          <base-icon icon="share"></base-icon>
          <div class="title">
            {{ $t('lookFeed.share') }}
          </div>

          <base-look-share :look="look"
                           ref="socialSharingDropdown"/>
        </div>
      </div>
    </div>

    <span ref="addToCartResultMessage">
      {{ addToCartResultText }}
    </span>
  </div>
</template>

<script>
  import { authenticated } from '@/services/auth'
  import lookService from '@/services/queries/lookQueries'

  export default {
      props: {
        look: {
          required: true,
          type: Object
        }
      },

      data() {
        return {
          liked: this.look.liked,
          addToCartResultText: '',
          isLikeIconHovered: false,
          likesNumber: this.look.likesNumber
        }
      },

      mounted() {
        this.initSocialSharingDropdown()
        this.initAddedToCartPopup()
      },

      methods: {
        onLike() {
          if (!authenticated()) {
            this.openAuthModal({ content: 'login' })
            return
          }

          if (!this.liked) {
            this.likesNumber++
            lookService.like(this.look.authorId, this.look.id)
          } else {
            this.likesNumber--
            lookService.removeLike(this.look.authorId, this.look.id)
          }

          this.liked = !this.liked
        },

        toCart() {
          if (!this.look.clothingItems.length) {
            this.showAddToCartTooltip('nothingToAddToCart')

            return
          }

          if (this.look.clothingItems.every(item => this.isItemInCart(item))) {
            this.showAddToCartTooltip('alreadyInCart')

            return
          }

          this.addToCart(this.look)
          this.showAddToCartTooltip('addedToCart')
        },

        showAddToCartTooltip(messageType) {
          this.addToCartResultText = this.$t(`lookFeed.cart.${messageType}`)

          this.$nextTick(() => {
            this.$refs.cart._tippy.show()
          })
        },

        goToStudio() {
          this.clearLook()

          let lookCopy = _.cloneDeep(this.lookInState)
          lookCopy.items = this.look.items.map((item) => {
            return {
              ...item,
              clothingItemType: this.constants$.artboardPictureTypes.existingClothing,
              imageUrl: this.getImageUrl(item.clothing_item)
            }
          })

          this.setLook(lookCopy)

          this.$router.push({ name: 'studio' })
        },

        // TODO: Remove, use transformed data instead
        getImageUrl(good) {
          return good.images && good.images.length > 0 ?
              good.images[0].slices_info.filter((info) => {
                return info.title === 'middle'
              })[0].url : null
        },

        initSocialSharingDropdown() {
          tippy(this.$refs.socialSharing, {
            content: this.$refs.socialSharingDropdown.$el,
            interactive: true,
            theme: 'light dropdown-menu share',
            animation: 'shift-away',
            placement: 'bottom',
            arrow: true,
            duration: [600, 300],
            inertia: true
          })
        },

        initAddedToCartPopup() {
          tippy(this.$refs.cart, {
            content: this.$refs.addToCartResultMessage,
            animation: 'shift-away',
            placement: 'bottom',
            arrow: true,
            trigger: 'manual',
            onShown(tooltip) {
              setTimeout(() => {
                tooltip.hide()
              }, 800)
            },
          })
        },

        ...mapActions('cart', {
          addToCart: 'addLook'
        }),

        ...mapActions('studio', [
          'clearLook',
          'setLook'
        ]),

        ...mapActions('system', [
          'openAuthModal'
        ])
      },

      computed: {
        ...mapGetters('studio', {
          lookInState: 'getLook'
        }),

        ...mapGetters('cart', [
          'isItemInCart'
        ])
      }
    }
</script>

<style lang="scss" scoped>
  .look-item {
    display: flex;
    width: 100%;
    margin-bottom: 30px;

    background: #fff;
    box-shadow: 0 6px 10px 0 rgba(240, 240, 240, 0.50);
    border-radius: 6px;

    .preview {
      flex: 1 0;

      img {
        width: 100%;
      }
    }

    .info {
      display: flex;
      flex-direction: column;
      flex-basis: 452px;
      flex-shrink: 0;
      padding: 20px 20px 10px 20px;

      .user {
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        .name-and-date {
          margin-left: 10px;
        }

        .name {
          font-size: 14px;
          line-height: 23px;
        }

        .date {
          font-size: 12px;
          color: #B7B7B7;
        }
      }

      & > .title {
        font-weight: bold;
        font-size: 26px;
        color: black;
        letter-spacing: -0.35px;
        line-height: 38px;

        margin-bottom: 10px;
      }

      .description {
        font-size: 14px;
        color: #797979;
        line-height: 23px;
        white-space: pre-wrap;

        margin-bottom: 16px;
      }

      // TODO: Use mixing because of code-duplication in single good view
      .meta {
        list-style-type: none;
        margin: 0 0 20px 0;
        padding: 0;
      }

      .characteristic {
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        .name {
          margin-right: 10px;
          font-size: 12px;
          color: #B7B7B7;
        }

        .value {
          font-size: 14px;
          color: #797979;

          a {
            font-size: 14px;
          }
        }
      }

      .actions {
        display: flex;
        justify-content: center;
        width: 452px;
        height: 90px;
        flex-basis: 90px;
        margin-top: auto;
        border-top: 1px solid #F4F4F4;

        .action {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          width: 113px;
          height: 100%;
          cursor: pointer;

          & > .title {
            transition: all 0.3s;
          }

          &:hover {
            .title {
              color: $primary-color;
            }
          }

          .title {
            font-size: 14px;
            color: #797979;
            line-height: 23px;
            margin-top: 8px;
          }
        }
      }
    }
  }
</style>
