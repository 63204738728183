import lookTransofmer from '@/services/transformers/lookTransformer'

const baseURL = 'feed'

export default {
  /**
   * Get feed.
   *
   * @param params
   * @returns Promise
   */
  get(params = {}) {
    return axios.get(baseURL, {
      params,

      transformResponse: (data) => {
        return Object.assign({}, data, { items: data.items.map(lookTransofmer) })
      }
    }).then(response => response.data)
  }
}