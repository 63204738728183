<template>
  <div class="look-feed-filter-action-buttons">
    <a href="#" @click.prevent="$emit('clear')">
      {{ $t('lookFeed.lookFeedFilterActionButtons.clear') }}
    </a>
    <base-button :square="true"
                 size="small"
                 class="apply-button"
                 @click="$emit('apply')">
      {{ $t('lookFeed.lookFeedFilterActionButtons.apply') }}
    </base-button>
  </div>
</template>

<script>
  export default {

  }
</script>

<style lang="scss" scoped>
  .look-feed-filter-action-buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .apply-button {
      margin-left: 10px;
    }
  }
</style>