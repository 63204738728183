<template>
  <div class="look-feed-header">
    <ul class="filters">
      <li class="filter">
        <look-feed-filter-context @apply="updateFilters"></look-feed-filter-context>
      </li>
      <li class="filter">
        <look-feed-filter-price @apply="updateFilters"></look-feed-filter-price>
      </li>
      <li class="filter" ref="authorFilter">
        <look-feed-filter-author @apply="updateFilters"></look-feed-filter-author>
      </li>
    </ul>

    <div class="right">
      <base-checkbox v-model="params.is_only_agents"
                     :label="$t('lookFeed.lookFeedHeader.onlyAgents')"></base-checkbox>

      <base-checkbox v-model="params.is_only_followings"
                     :label="$t('lookFeed.lookFeedHeader.onlyFollowings')"
                     class="only-followings"></base-checkbox>
    </div>
  </div>
</template>

<script>
  import LookFeedFilterContext from './LookFeedFilterContext.vue'
  import LookFeedFilterPrice from './LookFeedFilterPrice.vue'
  import LookFeedFilterAuthor from './LookFeedFilterAuthor.vue'

  export default {
    components: {
      LookFeedFilterContext,
      LookFeedFilterPrice,
      LookFeedFilterAuthor
    },

    data() {
      return {
        params: {
          is_only_agents: false,
          is_only_followings: false
        }
      }
    },

    methods: {
      updateFilters(value) {
        this.params = {...this.params, ...value}
      }
    },

    watch: {
      params: {
        handler: function() {
          this.$emit('paramsUpdate', this.params)
        },
        deep: true
      }
    }
  }
</script>

<style lang="scss" scoped>
  .look-feed-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    .filters {
      display: flex;
      list-style-type: none;
      margin: 0;
      padding: 0;

      .filter {
        margin-right: 20px;
      }
    }

    .right {
      display: flex;
      align-items: center;

      .only-followings {
        margin-left: 20px;
      }
    }
  }
</style>
