<template>
  <div class="look-feed-filter-author">
    <div class="label"
         :class="{ active: isOpen }"
         ref="authorFilter">
      {{ $t('lookFeed.lookFeedFilterAuthor.title') }}
    </div>
    <div class="dropdown"
         ref="authorFilterDropdown">
      <base-multiselect v-model="selectedAuthor"
                        :options="authors"
                        option-label="nickName"
                        track-by="id"
                        :placeholder="$t('lookFeed.lookFeedFilterAuthor.searchPlaceholder') "
                        open-direction="bottom"
                        :loading="loading"
                        :internal-search="false"
                        :clear-on-select="false"
                        :preserve-search="true"
                        :options-limit="10"
                        :limit="3"
                        :max-height="600"
                        @search-change="search">
        <template slot="option" slot-scope="props">
          <div class="user">
            <base-avatar :user="props.option"></base-avatar>
            <div class="nickname">{{ props.option.nick_name }}</div>
          </div>
        </template>
      </base-multiselect>

      <look-feed-filter-action-buttons
              class="actions"
              @clear="clear"
              @apply="apply"></look-feed-filter-action-buttons>
    </div>
  </div>
</template>

<script>
  import profileService from '@/services/queries/profileQueries.js'
  import LookFeedFilterActionButtons from './LookFeedFilterActionButtons.vue'

  export default {
    components: {
      LookFeedFilterActionButtons
    },

    data() {
      return {
        selectedAuthor: null,
        authors: [],
        isOpen: false,
        loading: false
      }
    },

    mounted() {
      let vm = this

      tippy(this.$refs.authorFilter, {
        content: this.$refs.authorFilterDropdown,
        interactive: true,
        trigger: 'click',
        theme: 'light dropdown-menu',
        animation: 'shift-away',
        arrow: true,
        placement: 'bottom',
        inertia: true,
        duration: [600, 300],
        onShow() {
          vm.isOpen = true
        },
        onHide() {
          vm.isOpen = false
        }
      })
    },

    methods: {
      search: _.debounce(function(query) {
        this.loading = true

        let params = {
          search_text: query,
          limit: 5,
          "look_count.min": 1
        }

        profileService.get(params).then(response => {
          if (query === '') {
            this.authors = []
          } else {
            this.authors = response.items
          }

          this.loading = false
        })
      }, 500),

      close() {
        this.$refs.authorFilter._tippy.hide()
      },

      clear() {
        this.selectedAuthor = null
        this.apply()
      },

      apply() {
        this.close()
        this.$emit('apply', {
          author_id: this.selectedAuthor ? this.selectedAuthor.id : null
        })
      }
    }
  }
</script>

<style lang="scss" scoped>


  .look-feed-filter-author {
    .label {
      font-size: 14px;
      color: #797979;
      cursor: pointer;

      &:hover, &.active {
        color: $primary-color;
      }
    }
  }

  .dropdown {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 600px;

    .actions {
      justify-content: flex-end;
      margin-left: 100px;
    }
  }

  .user {
    display: flex;
    align-items: center;

    .nickname {
      margin-left: 10px;
    }
  }

  /deep/ .multiselect, /deep/ .multiselect__input, /deep/ .multiselect__single {
    font-size: 14px;
  }
</style>
