<template>
  <div class="look-feed-filter-context">
    <div class="label" :class="{ active: isOpen }" ref="contextFilter">
      {{ $t('lookFeed.lookFeedFilterContext.title') }}
      <span class="indicator" v-if="numberOfSelectedContexts > 0">
        {{ numberOfSelectedContexts }}
      </span>
    </div>
    <div class="dropdown" ref="contextFilterDropdown">
      <look-feed-filter-action-buttons
              class="actions"
              @clear="clear"
              @apply="apply"></look-feed-filter-action-buttons>

      <ul class="contexts-list">
        <li v-for="lookContext in $store.state.handbooks.lookContexts">
          <base-checkbox :label="lookContext.title"
                         v-model="lookContext.checked"></base-checkbox>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  import LookFeedFilterActionButtons from './LookFeedFilterActionButtons.vue'

  export default {
    components: {
      LookFeedFilterActionButtons
    },

    data() {
      return {
        lookContexts: [],
        isOpen: false
      }
    },

    created() {
      this.lookContexts = _.cloneDeep(this.getLookContexts).map(context => Object.assign({}, context, {
        checked: false
      }))
    },

    mounted() {
      let vm = this

      tippy(this.$refs.contextFilter, {
        content: this.$refs.contextFilterDropdown,
        interactive: true,
        trigger: 'click',
        theme: 'light dropdown-menu',
        animation: 'shift-away',
        arrow: true,
        placement: 'bottom',
        inertia: true,
        duration: [600, 300],
        onShow() {
          vm.isOpen = true
        },
        onHide() {
          vm.isOpen = false
        }
      })
    },

    methods: {
      close() {
        this.$refs.contextFilter._tippy.hide()
      },

      clear() {
        this.lookContexts.forEach(context => context.checked = false)
        this.apply()
      },

      apply() {
        this.close()
        this.$emit('apply', {
          look_context_id: this.processedContexts
        })
      }
    },

    computed: {
      numberOfSelectedContexts() {
        return this.lookContexts.filter(context => context.checked === true).length
      },

      processedContexts() {
        let contexts = this.lookContexts.reduce((result, context) => {
          if (context.checked) {
            result.push(context.look_context_id)
          }

          return result
        }, [])

        if (contexts.length <= 0) {
          return null
        }

        return contexts.join(',')
      }
    }
  }
</script>

<style lang="scss" scoped>


  .look-feed-filter-context {
    .label {
      display: flex;
      align-items: center;

      font-size: 14px;
      color: #797979;
      cursor: pointer;

      &:hover, &.active {
        color: $primary-color;
      }

      .indicator {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin-left: 4px;

        width: 14px;
        height: 14px;
        border-radius: 50%;
        background-color: $primary-color;

        font-size: 9px;
        color: #FFFFFF;
      }
    }
  }

  .actions {
    margin-left: auto;
    padding: 5px 0 15px 0;
    margin-bottom: 10px;
    border-bottom: 1px solid #F4F4F4;
  }

  .dropdown {
    width: 800px;

    .contexts-list {
      list-style-type: none;
      padding: 0;
      margin: 0;
      column-count: 3;
      column-fill: balance;

      & > li {
        padding: 10px;
        break-inside: avoid;
      }
    }
  }
</style>
